import { Grid2, Typography } from "@mui/material";
import React from "react";

export default function PassiveRow({ field, value } : { field: string, value: string }) {
    return (
        <>
            <Grid2 size={4}>
                <Typography variant="body2">{field}</Typography>
            </Grid2>
            <Grid2 size={8}>
                <Typography variant="body2">{value}</Typography>
            </Grid2>
        </>
    );
}