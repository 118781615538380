import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../hooks";
import { aetherApi, GetOrCreateSessionParams } from "../api/aetherApi";
import { CheckoutSession } from "../../models/CheckoutSession";

export interface CheckoutState {
    sessionParams: GetOrCreateSessionParams | null;
    editingStep: string | null;
};

const initialState: CheckoutState = {
    sessionParams: null,
    editingStep: null
};

export const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        updateSessionParams: (state, action) => {
            state.sessionParams = action.payload;
        },
        editStep: (state, action) => {
            state.editingStep = action.payload;
        },
        completeEditStep: (state) => {
            state.editingStep = null;
        },
    },
});

export const { updateSessionParams, editStep, completeEditStep } = checkoutSlice.actions;

export default checkoutSlice.reducer;