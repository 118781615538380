import React from "react";
import { Card, Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { selectCurrency, selectItems, selectOrderTotals, selectParams } from "../../redux/selectors/checkoutSelectors";
import { ItemListItem } from "./ItemListItem";

export default function ItemsCard() {
    const params = useAppSelector(selectParams);
    const items = useAppSelector((state) => selectItems(state, params));
    const currency = useSelector(selectCurrency);
    const { subtotal, total } = useAppSelector((state) => selectOrderTotals(state, params));

    return (
        <Card variant="outlined" data-testid="itemcard">
            <Typography variant="h6" align="center" mt={1}>Items</Typography>
            {
                items?.map((item, idx) => [
                    <ItemListItem key={idx} item={item} data-testid={"listItem" + idx} />
                ])
            }
            <Divider />
            <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                <Typography variant="body2">Subtotal</Typography>
                <Typography variant="body2">{currency.currencySymbol}{subtotal}</Typography>
            </Stack>
            <Divider />
            <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                <Typography variant="body1">Total</Typography>
                <Typography variant="body1">{currency.currencySymbol}{total}</Typography>
            </Stack>
        </Card>
    );
}