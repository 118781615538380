import { createSelector } from "reselect";
import { RootState } from "../hooks";
import { aetherApi, GetOrCreateSessionParams } from "../api/aetherApi";
import { CheckoutSession, CheckoutStatus, CheckoutStep } from "../../models/CheckoutSession";

export const selectParams = (state: RootState) => state.checkout.sessionParams;

export const selectSession = createSelector([
        (state: RootState) => state,
        (_, params: GetOrCreateSessionParams | null) => params
    ],
    (state, params) => params != null ? aetherApi.endpoints.getOrCreateCheckoutSession.select(params)(state).data ?? null : null
);

export const selectOrder = createSelector(
    selectSession,
    (session) => session?.order
);

export const selectAetherOrderData = createSelector(
    selectOrder,
    (order) => order?.aetherData ?? null
);

export const selectSubtotal = createSelector(
    selectOrder,
    (order) => order?.aetherData?.displaySubtotal ?? 0
);

export const selectTaxAmount = createSelector(
    selectOrder,
    (order) => order?.aetherData?.displayTax ?? 0
);

export const selectTotal = createSelector(
    selectOrder,
    (order) => order?.aetherData?.displayTotal ?? 0
);

export const selectItems = createSelector(
    selectSession,
    (session) => session?.order.items ?? null
);

export const selectOrderTotals = createSelector([
        selectSubtotal,
        selectTaxAmount,
        selectTotal
    ],
    (subtotal, tax, total) => ({ subtotal, tax, total })
);

export const selectStatus = createSelector(
    selectSession,
    (session) => session?.status
);

export const selectActiveStep = createSelector([
        selectStatus,
        (state: RootState) => state.checkout.editingStep, 
    ],
    (status, editingStep) => {
        if (editingStep) {
            return editingStep;
        } else {
            switch (status) {
                case CheckoutStatus.PENDING_DETAILS:
                    return CheckoutStep.CUSTOMER_DETAILS;
                case CheckoutStatus.PENDING_RECEIVE_METHOD:
                    return CheckoutStep.RECEIVE_METHOD;
                case CheckoutStatus.PENDING_PAYMENT:
                    return CheckoutStep.PAYMENT;
                default:
                    return "CustomerDetails";
            }
        }
    }
);

export const selectQuestions = createSelector(
    selectAetherOrderData,
    (aetherOrderData) => aetherOrderData?.questions ?? null
);

export const selectFirstName = createSelector(
    selectAetherOrderData,
    (aetherOrderData) => aetherOrderData?.firstName ?? ""
);

export const selectLastName = createSelector(
    selectAetherOrderData,
    (aetherOrderData) => aetherOrderData?.lastName ?? ""
);

export const selectEmail = createSelector(
    selectAetherOrderData,
    (aetherOrderData) => aetherOrderData?.email ?? ""
);

export const selectContactInfo = createSelector(
    [selectFirstName, selectLastName, selectEmail],
    (firstName, lastName, email) => ({ firstName, lastName, email })
);

export const selectSessionId = createSelector(
    selectSession,
    (session) => session?._id
);

export const selectCurrency = createSelector(
    (state: RootState) => state,
    (state) => state.currencyData
);

export const selectCartId = (state: RootState) => state.cartData.id; 
