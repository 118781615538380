import { Order } from "./Order";
import { OrderItem } from "./OrderItem";

export enum CheckoutStep {
    CUSTOMER_DETAILS = 'CustomerDetails',
    RECEIVE_METHOD = 'ReceiveMethod',
    PAYMENT = 'Payment',
}

export enum CheckoutStatus {
    PENDING_DETAILS = 'PendingDetails',
    PENDING_RECEIVE_METHOD = 'PendingReceiveMethod',
    PENDING_PAYMENT = 'PendingPayment',
    COMPLETED = 'Completed',
}

export interface CheckoutSession {
    _id: string;
    userId: string;
    cartId: string;
    status: CheckoutStatus;
    order: Order;
}