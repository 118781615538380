import uuid from "uuid/v4";
import moment from "moment";
import {
  ADD_ROWS_TO_CART,
  ADD_TO_CART,
  CART_DATA,
  COMPLETE_KIT,
  DECREASE_QUANTITY,
  DELETE_ALL_FROM_CART,
  DELETE_DECO_CART,
  DELETE_FROM_CART,
  DELETE_SIZE_FROM_CART,
  EDIT_COLOR_CART,
  EDIT_KIT_CART_ITEM,
  EDIT_QTY_CART,
  EDIT_SIZE_CART,
  getRowArray,
  UPDATE_TO_CART,
  UPDATE_TO_CART_COMMENTS,
} from "../actions/cartActions";
import { updateCart } from "../../util/cart/cartAPIUtil"

const initState = {
  _id: null,
  cartItems: [],
  pendingCartItems: [],
};

const cartReducer = (state = initState, action) => {
  let cartItems = state.cartItems
  let pendingCartItems = state.pendingCartItems
  let product = action.payload
  let publicIp = action.ipV4

  if (action.type === ADD_TO_CART) {
    // for non variant products
    if (product.variation == 'undefined') {
      const cartItem = cartItems.filter((item) => item.id === product.id)[0]
      if (cartItem === undefined) {
        return {
          ...state,
          cartItems: [
            ...cartItems,
            {
              ...product,
              quantity: product.quantity ? product.quantity : 1,
              cartItemId: uuid(),
              dateTime: moment(),
            },
          ],
          pendingCartItems,
        }
      } else {
        return {
          ...state,
          cartItems: cartItems.map((item) =>
            item.cartItemId === cartItem.cartItemId
              ? {
                ...item,
                quantity: product.quantity
                  ? item.quantity + product.quantity
                  : item.quantity + 1,
              }
              : item,
          ),
          pendingCartItems,
        }
      }
      // for variant products
    } else {
      const cartItem = cartItems.filter(
        (item) =>
          item.id === product.id &&
          product.selectedProductColor &&
          product.selectedProductColor === item.selectedProductColor &&
          product.selectedProductSize &&
          product.selectedProductSize === item.selectedProductSize &&
          (product.cartItemId ? product.cartItemId === item.cartItemId : true),
      )[0]

      if (cartItem === undefined) {
        return {
          ...state,
          cartItems: [
            ...cartItems,
            {
              ...product,
              quantity: product.quantity ? product.quantity : 1,
              cartItemId: uuid(),
            },
          ],
          pendingCartItems,
        }
      } else if (
        cartItem !== undefined &&
        (cartItem.selectedProductColor !== product.selectedProductColor ||
          cartItem.selectedProductSize !== product.selectedProductSize)
      ) {
        return {
          ...state,
          cartItems: [
            ...cartItems,
            {
              ...product,
              quantity: product.quantity ? product.quantity : 1,
              cartItemId: uuid(),
            },
          ],
          pendingCartItems,
        }
      } else {
        return {
          ...state,
          cartItems: cartItems.map((item) =>
            item.cartItemId === cartItem.cartItemId
              ? {
                ...item,
                quantity: product.quantity
                  ? item.quantity + product.quantity
                  : item.quantity + 1,
                selectedProductColor: product.selectedProductColor,
                selectedProductSize: product.selectedProductSize,
                selectedProductDecorations: product.selectedProductDecorations,
                personalizationArray: product.personalizationArray,
              }
              : item,
          ),
          pendingCartItems,
        }
      }
    }
  }

  if (action.type === UPDATE_TO_CART || action.type === UPDATE_TO_CART_COMMENTS) {
    const cartItemToSave = cartItems.map((item) =>
      item.cartItemId === product.cartItemId
        ? {
          ...item,
        }
        : item,
    )
    updateCart(cartItemToSave, publicIp);
    return {...state, cartItems: cartItemToSave, pendingCartItems};
  }

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          (cartItem) => cartItem.cartItemId !== product.cartItemId,
        )
      return {...state, cartItems: remainingItems(cartItems, product), pendingCartItems};
    } else {
      return {
        ...state,
        cartItems: cartItems.map((item) =>
          item.cartItemId === product.cartItemId
            ? { ...item, quantity: item.quantity - 1 }
            : item,
          ),
        pendingCartItems,
      };
    }
  }
  if (action.type === DELETE_FROM_CART) {
    let isPriceBreak = action.isPriceBreak
    const mainIndex = action.mainIndex;
    const rowIndex = action.rowIndex;
    let userGroupDiscount = action.userGroupDiscount
    console.log('userGroupDiscount: ', userGroupDiscount);
    const affectedCartItem = cartItems[mainIndex];
    const remainingItems = (cartItems, product) => {
      cartItems = cartItems.map((cartItem, index) => {
        cartItem = { ...cartItem };
        if (cartItem.product.id === product.id && index === mainIndex) {
          cartItem.currentOrder = cartItem.currentOrder.filter((rowSata, key) => key !== rowIndex)
          cartItem.dateTime = moment()
          return cartItem
        } else if (affectedCartItem?.product?.isKitEnabled && cartItem?.product?.kitId === affectedCartItem?.product?.kitId) {
          cartItem.currentOrder = [];
          return cartItem;
        } else {
          return cartItem
        }
      }).filter(cartItem => cartItem.currentOrder.length)
      if (isPriceBreak) {
        let totalSum = 0;
        let tempcatItems = cartItems.map((cartItem, index) => {
          if (cartItem.product.id == product.id) {
            return cartItem
          }
        }).filter(x => x);
        tempcatItems.map((object, index) => {
          object && object.currentOrder && object.currentOrder.length > 0 && object.currentOrder.map((curr, currIndex) => {
            curr && curr.length > 0 && curr.map((sizeObj, index) => {
              totalSum = totalSum + Number(sizeObj.quantity)
            })
          })
        })
        let priceArray = []
        let obj1;
        tempcatItems.map((catItem, index) => {
          let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.WebstoreProductPartArray : product?.ProductPartArray?.ProductPart
          catItem && catItem.currentOrder && catItem.currentOrder.length > 0 && catItem.currentOrder.map((curr, currIndex) => {
            curr && curr.length > 0 && curr.map((obj, index) => {
              obj1 = productPartArray.find((productPart) => productPart.color == obj.color && productPart.size == obj.size)
            })
            if (obj1) {
              priceArray.push(obj1)
            }
          })
        })
        let minQuantity = []
        priceArray && priceArray.length > 0 && priceArray.map((price) => {
          let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
            (val) => Number(val.minQuantity) <= Number(totalSum),
          )
          if (min) {
            minQuantity.push(min)
          }
        })
        let allMaxQty = []
        minQuantity.map((qtyMin) => {
          let maxQty = qtyMin && qtyMin.length > 0 && qtyMin.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
          if (maxQty) {
            allMaxQty.push(maxQty)
          }
        })
        let salePrice = allMaxQty && allMaxQty.length > 0 && allMaxQty.reduce((prev, current) => (Number(prev?.salePrice) > Number(current.salePrice)) ? prev : current)
        cartItems && cartItems.length > 0 && cartItems.map((cartItem, index) => {
          if (cartItem.product.id == product.id) {
            cartItem && cartItem.length > 0 && cartItem.currentOrder.map((curr, currIndex) => {
              curr && curr.length > 0 && curr.map((order, index) => {
                let withoutDiscount = (salePrice?.salePrice && Number(salePrice?.salePrice) != 0) ? Number(salePrice?.salePrice) : Number(order.price)
                console.log('withoutDiscount1: ', withoutDiscount);
                let showSell = false
                let showSellPrice = false
                let offerPrice = 0
                let discountType = ''

                let todayDate = new Date().toISOString()

                if (
                  todayDate >= cartItem?.product?.startDate &&
                  todayDate <= cartItem?.product?.endDate
                ) {
                  if (cartItem?.product?.makeProductSale) {
                    showSell = true
                  }
                } else if (
                  cartItem?.product?.startDate === '' ||
                  cartItem?.product?.startDate === undefined
                ) {
                  if (cartItem?.product?.makeProductSale) {
                    showSell = true
                  }
                }

                if (
                  cartItem?.product?.showPriceBreak &&
                  todayDate >= cartItem?.product?.startDate &&
                  todayDate <= cartItem?.product?.endDate
                ) {
                  showSellPrice = true
                }
                if (cartItem?.product?.sellingPrice) {
                  offerPrice = cartItem?.product?.sellingPrice
                }
                if (
                  cartItem?.product?.discountType === '0' ||
                  cartItem?.product?.discountType === '1'
                ) {
                  discountType = cartItem?.product?.discountType
                }

                if (Number(userGroupDiscount > 0) && Number(withoutDiscount) > 0) {
                  withoutDiscount =
                    Number(withoutDiscount) -
                    (Number(withoutDiscount) * Number(userGroupDiscount)) /
                    100
                  if (withoutDiscount < 0) {
                    withoutDiscount = 0
                  }
                }

                if (discountType === '1' && showSellPrice && Number(withoutDiscount) > 0) {
                  if (offerPrice > 0) {
                    withoutDiscount =
                      Number(withoutDiscount) - Number(offerPrice)
                    if (withoutDiscount < 0) {
                      withoutDiscount = 0
                    }
                  }
                } else if (discountType === '0' && showSellPrice && Number(withoutDiscount) > 0) {
                  if (offerPrice > 0) {
                    withoutDiscount =
                      Number(withoutDiscount) -
                      (Number(withoutDiscount) * Number(offerPrice)) / 100
                    if (withoutDiscount < 0) {
                      withoutDiscount = 0
                    }
                  }
                }
                console.log('withoutDiscount2: ', withoutDiscount);
                order.price = withoutDiscount
              })
            })
          }
        });
      }
      updateCart(cartItems, publicIp)
      return cartItems
    }
    return {...state, cartItems: remainingItems(cartItems, product), pendingCartItems};
  }
  if (action.type === DELETE_ALL_FROM_CART) {
    cartItems = cartItems.filter((item) => {
      return false
    })
    updateCart(cartItems, publicIp)

    return {...state, cartItems, pendingCartItems};

  }
  if (action.type === ADD_ROWS_TO_CART) {
    let newItem = action.payload
    let isPriceBreak = action.isPriceBreak
    let userGroupDiscount = action.userGroupDiscount
    console.log('userGroupDiscount: ', userGroupDiscount);
    let pending = action.pending ?? false;
    let isLayout = action.isLayout ?? 0
    let kitId = action.kitId
    let checkcartItem = cartItems.filter(
      (cartItem) => cartItem.product.cartItemId === newItem.product.cartItemId,
    )[0]
    let cartItemId = uuid();
    let newCartItem = {
      currentOrder: newItem.rowsData,
      product: { ...newItem.product, cartItemId, kitId },
      dateTime: moment(),
      pending: pending,
    }
    if (kitId && !newItem.product.isKitEnabled) {
      // Handle kit sub product
      let kitItems = pendingCartItems.filter((cartItem) => cartItem.product.kitId === kitId);
      let kitCartItem = kitItems[0];
      checkcartItem = kitItems.find((cartItem) => cartItem.product.id === newCartItem.product.id);
      const config = kitCartItem.product.kitProducts.find((c) => c.productId === newCartItem.product.id);
      newCartItem.isKitChildProduct = true;
      newCartItem.kitStartingQuantity = config.startingQuantity ?? 1;
      newCartItem.kitChangeQuantity = kitCartItem.product.kitChangeQuantity;
      newCartItem.kitPriceMode = kitCartItem.product.kitPriceMode;
      newCartItem.product.parentCartItemId = kitCartItem.product.cartItemId;
      if (newCartItem.kitPriceMode === 'kit') {
        newCartItem.currentOrder.forEach((rowArray) => {
          rowArray.forEach((row) => {
            row.price = 0;
            row.totalPrice = 0;
          });
        });
      }
    }

    let updatedCartItems = [...(cartItems ? cartItems : [])];
    let updatedPendingCartItems = [...(pendingCartItems ? pendingCartItems : [])];

    if (checkcartItem === undefined) {
      if (pending) {
        updatedPendingCartItems.push(newCartItem);
      } else {
        updatedCartItems.push(newCartItem);
      }
    } else {
      if (pending) {
        updatedPendingCartItems = updatedPendingCartItems.map((cartItem) => {
          if (cartItem.product.id === newCartItem.product.id) {
            return newCartItem;
          }
          return cartItem;
        });
      } else {
        updatedCartItems = updatedCartItems.map((cartItem) => {
          if (cartItem.product.id === newCartItem.product.id) {
            return newCartItem;
          }
          return cartItem;
        });

      }
    }
    if (newCartItem?.product?.isKitEnabled && newCartItem?.product?.kitAutoAddProducts && newCartItem?.product?.kitProductData) {
      for (const kitProduct of newCartItem.product.kitProductData) {
        const kitProductConfig = newCartItem.product.kitProducts.find((c) => c.productId === kitProduct.id);
        const quantity = kitProductConfig?.startingQuantity ?? 1;
        const row = getRowArray(kitProduct, null, quantity, userGroupDiscount, null, null, null, null, userGroupDiscount);
        if (newCartItem.product.kitPriceMode === "kit") {
          row.price = 0;
          row.totalPrice = 0;
        }
        const rowData = [[row]];
        const kitCartProduct = {
          currentOrder: rowData,
          dateTime: moment(),
          product: {
            ...kitProduct,
            parentCartItemId: newCartItem.product.cartItemId,
            cartItemId: uuid(),
            kitId: newCartItem.product.kitId,
          },
          isKitChildProduct: true,
          kitStartingQuantity: quantity,
          kitChangeQuantity: newItem.product.kitChangeQuantity,
          kitPriceMode: newItem.product.kitPriceMode,

        };
        updatedCartItems.push(kitCartProduct);
      }
    }
    if (isPriceBreak) {
      let totalSum = 0;
      let tempcatItems = updatedCartItems.map((cartItem, index) => {
        if (cartItem.product.id == newItem.product.id) {
          return cartItem
        }
      }).filter(x => x);
      tempcatItems.map((object, index) => {
        object.currentOrder && object.currentOrder.map((curr, currIndex) => {
          curr && curr.length > 0 && curr.map((sizeObj, index) => {
            totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
          })
        })
      })
      let obj1;
      let rPrice = 0
      tempcatItems.map((catItem, index) => {
        let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
        catItem?.currentOrder && catItem?.currentOrder.length > 0 && catItem.currentOrder.map((curr, currIndex) => {
          curr.map((obj, index) => {
            obj1 = productPartArray.find((productPart) => productPart.color === obj.color && productPart.size === obj.size)
            if (obj1?.size === obj?.size && obj?.color === obj1?.ColorArray?.Color?.colorName) {
              obj1.partPrice.PartPriceArray.PartPrice.map(
                  (partPrice, k) => {
                    if (
                        Number(partPrice.minQuantity) <= Number(totalSum)
                    ) {
                      rPrice = partPrice.salePrice
                    } else if (k === 0) {
                      rPrice = partPrice.salePrice
                    }
                  },
              )
            }
            let withoutDiscount = rPrice
            console.log('withoutDiscount1: ', withoutDiscount);
            let showSell = false
            let showSellPrice = false
            let offerPrice = 0
            let discountType = ''

            let todayDate = new Date().toISOString()

            if (
                todayDate >= newItem?.product?.startDate &&
                todayDate <= newItem?.product?.endDate
            ) {
              if (newItem?.product?.makeProductSale) {
                showSell = true
              }
            } else if (
                newItem?.product?.startDate === '' ||
                newItem?.product?.startDate === undefined
            ) {
              if (newItem?.product?.makeProductSale) {
                showSell = true
              }
            }

            if (
                newItem?.product?.showPriceBreak &&
                todayDate >= newItem?.product?.startDate &&
                todayDate <= newItem?.product?.endDate
            ) {
              showSellPrice = true
            }
            if (newItem?.product?.sellingPrice) {
              offerPrice = newItem?.product?.sellingPrice
            }
            if (
                newItem?.product?.discountType === '0' ||
                newItem?.product?.discountType === '1'
            ) {
              discountType = newItem?.product?.discountType
            }
            if (Number(userGroupDiscount > 0) && Number(withoutDiscount) > 0 && isLayout === 0) {
              withoutDiscount =
                  Number(withoutDiscount) -
                  (Number(withoutDiscount) * Number(userGroupDiscount)) /
                  100
              if (withoutDiscount < 0) {
                withoutDiscount = 0
              }
            }

            if (discountType === '1' && showSellPrice && Number(withoutDiscount) > 0 && isLayout === 0) {
              if (offerPrice > 0) {
                withoutDiscount =
                    Number(withoutDiscount) - Number(offerPrice)
                if (withoutDiscount < 0) {
                  withoutDiscount = 0
                }
              }
            } else if (discountType === '0' && showSellPrice && Number(withoutDiscount) > 0 && isLayout === 0) {
              if (offerPrice > 0) {
                withoutDiscount =
                    Number(withoutDiscount) -
                    (Number(withoutDiscount) * Number(offerPrice)) / 100
                if (withoutDiscount < 0) {
                  withoutDiscount = 0
                }
              }
            }
            obj.price = withoutDiscount
          })
        })
      })
    }
    updateCart(updatedCartItems, publicIp)
    return {...state, cartItems: updatedCartItems, pendingCartItems: updatedPendingCartItems};
  }
  if (action.type === DELETE_DECO_CART) {
    let rowIndexTemp = action.rowIndex
    let decoIndexTemp = action.decoIndex
    let rowMainIndexTemp = action.mainIndex

    const remainingItems = (cartItems, product) => {
      cartItems.map((cartItem, cartIndex) => {
        cartItem = { ...cartItem };
        if (cartItem.product.id == product.product.id && rowMainIndexTemp === cartIndex) {
          let tempOrder = cartItem.currentOrder
          if (tempOrder[rowIndexTemp]) {
            let tempRows = tempOrder[rowIndexTemp]
            tempRows = tempRows.filter((rowData) => {
              if (rowData.decorations[decoIndexTemp]) {
                rowData.decorations = rowData.decorations.filter(
                  (deco, key) => key !== decoIndexTemp,
                )
              }
              return rowData
            })
            tempOrder[rowIndexTemp] = tempRows
          }
          tempOrder = tempOrder.filter((rowArray) => rowArray.length)
          cartItem.currentOrder = tempOrder
          return cartItem
        } else {
          return cartItem
        }
      })
      cartItems = cartItems.filter((item) => item.currentOrder.length)
      updateCart(cartItems, publicIp)
      return cartItems
    }
    return {...state, cartItems: remainingItems(cartItems, product), pendingCartItems};
  }
  if (action.type === EDIT_COLOR_CART) {
    const remainingItems = (cartItems, product) => {
      let rowIndexTemp = action.rowIndex
      let colorIndex = action.color
      let imageIndex = action.image
      cartItems.filter((cartItem, index) => {
        if (
          index == rowIndexTemp &&
          cartItem.product.id == product.product.id
        ) {
          let tempOrder = cartItem.currentOrder

          if (tempOrder) {
            let tempRow = tempOrder[0]
            if (tempRow[0]) {
              tempRow[0].color = colorIndex
              tempRow[0].image = imageIndex
              tempOrder[0] = tempRow
            }
            tempOrder[0] = tempRow
          }
          cartItem.currentOrder = tempOrder
          cartItem.dateTime = moment()
          return cartItem
        } else {
          return cartItem
        }
      })
      cartItems = cartItems.filter((item) => item.currentOrder.length)
      updateCart(cartItems, publicIp)
      return cartItems
    }
    return {...state, cartItems: remainingItems(cartItems, product), pendingCartItems};
  }
  if (action.type === EDIT_QTY_CART) {
    let sizeindexTemp = action.sizeindex
    let newItem = action.payload
    let userGroupDiscount = action.userGroupDiscount
    let rowIndexTemp = action.rowIndex
    let mainRowIndex = action.mainRowIndex
    let qtyTemp = action.qty
    let totalQty = Number(qtyTemp)
    let kitProductCartId = newItem?.product?.isKitEnabled ? newItem?.product?.cartItemId : null
    const remainingItems = (cartItems, product) => {
      let isPriceBreak = action.isPriceBreak
      cartItems.filter((cartItem, index) => {
        if (cartItem.product.id === product.product.id) {
          let tempOrder = cartItem.currentOrder
          if (tempOrder) {
            let tempRow = tempOrder[mainRowIndex]
            if (tempRow && tempRow.length >= sizeindexTemp && tempRow[sizeindexTemp]) {
              let productSize = tempRow[sizeindexTemp].size
              let productColor = tempRow[sizeindexTemp].color
              let productPartArray = product?.product?.WebstoreProductPartArray && product?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
              let productSizeData =
                productPartArray.filter(
                  (p) => p.ApparelSize.labelSize == productSize,
                )
              let productPriceData = []
              if (productSizeData.length > 0) {
                let productColorData = productSizeData.filter(
                  (p) => p.ColorArray.Color.colorName == productColor,
                )
                if (productColorData.length > 0) {
                  productPriceData =
                    productColorData[0]?.partPrice?.PartPriceArray?.PartPrice.filter(
                      (val) => Number(val.minQuantity) <= Number(totalQty),
                    )

                  if (productPriceData.length > 0) {
                    productPriceData = productPriceData.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
                  }

                }
              }
              if (index === rowIndexTemp) {
                tempRow[sizeindexTemp].quantity = qtyTemp
                if (!cartItem.isKitChildProduct || cartItem.kitPriceMode !== 'kit') {
                  let withoutDiscount = Object.keys(productPriceData).length > 0 ? productPriceData?.salePrice : tempRow[sizeindexTemp].price
                  tempRow[sizeindexTemp].price = withoutDiscount
                    
                }
              }
              if (tempRow)
                tempOrder[mainRowIndex] = [
                  getRowArray(
                    cartItem.product,
                    null,
                    tempRow[0].quantity,
                    tempRow[0].color,
                    tempRow[0].size,
                    tempRow[0].decorations,
                    tempRow[0].personalization,
                    tempRow[0].personalizationCost,
                    userGroupDiscount,
                  )
                ]
            }
            if (tempRow)
              tempOrder[mainRowIndex] = [
                getRowArray(
                  cartItem.product,
                  null,
                  tempRow[0].quantity,
                  tempRow[0].color,
                  tempRow[0].size,
                  tempRow[0].decorations,
                  tempRow[0].personalization,
                  tempRow[0].personalizationCost,
                  userGroupDiscount,
                )
              ]
          }
          //tempOrder = tempOrder.filter((rowArray) => rowArray.length)
          cartItem.currentOrder = tempOrder
          cartItem.dateTime = moment()
          return cartItem
        }
        else {
          // Ensure kit sub products are updated with the correct quantity
          if (kitProductCartId && cartItem?.product?.parentCartItemId === kitProductCartId) {
            for (let row of cartItem.currentOrder) {
              for (let size of row) {
                size.quantity = qtyTemp * cartItem.kitStartingQuantity;
              }
            }
          }
          return cartItem
        }
      })
      if (isPriceBreak) {
        let totalSum = 0;
        let tempcatItems = cartItems.map((cartItem, index) => {
          if (cartItem.product.id == newItem.product.id) {
            return cartItem
          }
        }).filter(x => x);
        tempcatItems.map((object, index) => {
          object && object.currentOrder.map((curr, currIndex) => {
            curr && curr.length > 0 && curr.map((sizeObj, index) => {
              totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
            })
          })
        })
        let priceArray = []
        let obj1;
        tempcatItems.map((catItem, index) => {
          let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
          catItem?.currentOrder && catItem?.currentOrder.length > 0 && catItem.currentOrder.map((curr, currIndex) => {
            curr.map((obj, index) => {
              obj1 = productPartArray.find((productPart) => productPart.color == obj.color && productPart.size == obj.size)
              let rPrice = 0
              if (obj1?.size === obj?.size && obj?.color === obj1?.ColorArray?.Color?.colorName) {
                obj1.partPrice.PartPriceArray.PartPrice.map(
                  (partPrice, k) => {
                    if (
                      Number(partPrice.minQuantity) <= Number(totalSum)
                    ) {
                      rPrice = partPrice.salePrice
                    } else if (k === 0) {
                      rPrice = partPrice.salePrice
                    }
                  },
                )
                let withoutDiscount = rPrice
                console.log('withoutDiscount1: ', withoutDiscount);
                let showSell = false
                let showSellPrice = false
                let offerPrice = 0
                let discountType = ''

                let todayDate = new Date().toISOString()

                if (
                  todayDate >= catItem?.product?.startDate &&
                  todayDate <= catItem?.product?.endDate
                ) {
                  if (catItem?.product?.makeProductSale) {
                    showSell = true
                  }
                } else if (
                  catItem?.product?.startDate === '' ||
                  catItem?.product?.startDate === undefined
                ) {
                  if (catItem?.product?.makeProductSale) {
                    showSell = true
                  }
                }

                if (
                  catItem?.product?.showPriceBreak &&
                  todayDate >= catItem?.product?.startDate &&
                  todayDate <= catItem?.product?.endDate
                ) {
                  showSellPrice = true
                }
                if (catItem?.product?.sellingPrice) {
                  offerPrice = catItem?.product?.sellingPrice
                }
                if (
                  catItem?.product?.discountType === '0' ||
                  catItem?.product?.discountType === '1'
                ) {
                  discountType = catItem?.product?.discountType
                }

                if (Number(userGroupDiscount > 0) && Number(withoutDiscount) > 0) {
                  withoutDiscount =
                    Number(withoutDiscount) -
                    (Number(withoutDiscount) * Number(userGroupDiscount)) /
                    100
                  if (withoutDiscount < 0) {
                    withoutDiscount = 0
                  }
                }

                if (discountType === '1' && showSellPrice && Number(withoutDiscount) > 0) {
                  if (offerPrice > 0) {
                    withoutDiscount =
                      Number(withoutDiscount) - Number(offerPrice)
                    if (withoutDiscount < 0) {
                      withoutDiscount = 0
                    }
                  }
                } else if (discountType === '0' && showSellPrice && Number(withoutDiscount) > 0) {
                  if (offerPrice > 0) {
                    withoutDiscount =
                      Number(withoutDiscount) -
                      (Number(withoutDiscount) * Number(offerPrice)) / 100
                    if (withoutDiscount < 0) {
                      withoutDiscount = 0
                    }
                  }
                }
                obj.price = withoutDiscount
              }
            })
            /*if(obj1){
              priceArray.push(obj1)
            }*/
          })
        })
        /*let minQuantity = []
        priceArray.map((price) => {
          let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
            (val) => Number(val.minQuantity) <= Number(totalSum),
          )
          minQuantity.push(min)
        })
        let allMaxQty = []
        minQuantity.map((qtyMin) => {
          let maxQty = qtyMin && qtyMin.length>0 && qtyMin.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
          if (maxQty) {
            allMaxQty.push(maxQty)
          }
        })
        let salePrice = allMaxQty.length > 0 && allMaxQty.reduce((prev, current) => (Number(prev?.salePrice) > Number(current?.salePrice)) ? prev : current)
        cartItems.map((cartItem, index) => {
          if (cartItem.product.id == newItem.product.id) {
            cartItem.currentOrder.map((curr,currIndex)=>{
              curr.map((order, index) => {
                order.price = (salePrice?.salePrice && Number(salePrice?.salePrice) != 0) ? Number(salePrice?.salePrice) : Number(order.price)
              })
            })
          }
        });*/
      }
      cartItems = cartItems.filter((item) => item.currentOrder.length)
      updateCart(cartItems, publicIp)
      return cartItems
    }
    return {...state, cartItems: remainingItems(cartItems, product), pendingCartItems};
  }
  if (action.type === EDIT_SIZE_CART) {
    let newItem = action.payload
    let sizeindexTemp = action.sizeindex
    let rowIndexTemp = action.rowIndex
    let qtySize = action.size
    let mainRowIndex = action.mainRowIndex
    let isPriceBreak = action.isPriceBreak
    let color = action.color;
    let userGroupDiscount = action.userGroupDiscount
    console.log('userGroupDiscount: ', userGroupDiscount);

    const remainingItems = (cartItems, product) => {
      let partArray = []
      if (product?.product?.WebstoreProductPartArray && product?.product?.WebstoreProductPartArray.length > 0) {
        partArray = product.product.WebstoreProductPartArray
      } else {
        partArray = product?.product?.ProductPartArray?.ProductPart
      }
      let productPartData = partArray
      cartItems.filter((cartItem, index) => {

        if (cartItem.product.id == product.product.id && index === mainRowIndex) {
          let tempOrder = cartItem.currentOrder
          if (tempOrder[rowIndexTemp]) {
            let tempRow = tempOrder[rowIndexTemp]
            if (tempRow[sizeindexTemp]) {
              let newPrice = tempRow[sizeindexTemp].price
              productPartData.map((val, i) => {
                if (val.ApparelSize.labelSize == qtySize && val.ColorArray.Color.colorName === color) {
                  newPrice =
                    val.partPrice.PartPriceArray.PartPrice[0].salePrice
                }
              })
              let withoutDiscount = newPrice
              console.log('withoutDiscount1: ', withoutDiscount);
              let showSell = false
              let showSellPrice = false
              let offerPrice = 0
              let discountType = ''

              let todayDate = new Date().toISOString()

              if (
                todayDate >= cartItem?.product?.startDate &&
                todayDate <= cartItem?.product?.endDate
              ) {
                if (cartItem?.product?.makeProductSale) {
                  showSell = true
                }
              } else if (
                cartItem?.product?.startDate === '' ||
                cartItem?.product?.startDate === undefined
              ) {
                if (cartItem?.product?.makeProductSale) {
                  showSell = true
                }
              }

              if (
                cartItem?.product?.showPriceBreak &&
                todayDate >= cartItem?.product?.startDate &&
                todayDate <= cartItem?.product?.endDate
              ) {
                showSellPrice = true
              }
              if (cartItem?.product?.sellingPrice) {
                offerPrice = cartItem?.product?.sellingPrice
              }
              if (
                cartItem?.product?.discountType === '0' ||
                cartItem?.product?.discountType === '1'
              ) {
                discountType = cartItem?.product?.discountType
              }

              if (Number(userGroupDiscount > 0) && Number(withoutDiscount) > 0) {
                withoutDiscount =
                  Number(withoutDiscount) -
                  (Number(withoutDiscount) * Number(userGroupDiscount)) /
                  100
                if (withoutDiscount < 0) {
                  withoutDiscount = 0
                }
              }

              if (discountType === '1' && showSellPrice && Number(withoutDiscount) > 0) {
                if (offerPrice > 0) {
                  withoutDiscount =
                    Number(withoutDiscount) - Number(offerPrice)
                  if (withoutDiscount < 0) {
                    withoutDiscount = 0
                  }
                }
              } else if (discountType === '0' && showSellPrice && Number(withoutDiscount) > 0) {
                if (offerPrice > 0) {
                  withoutDiscount =
                    Number(withoutDiscount) -
                    (Number(withoutDiscount) * Number(offerPrice)) / 100
                  if (withoutDiscount < 0) {
                    withoutDiscount = 0
                  }
                }
              }
              tempRow[sizeindexTemp].size = qtySize
              tempRow[sizeindexTemp].price = withoutDiscount
              tempOrder[rowIndexTemp] = tempRow
            }
            tempOrder[rowIndexTemp] = tempRow
          }
          tempOrder = tempOrder.filter((rowArray) => rowArray.length)
          cartItem.currentOrder = tempOrder
          cartItem.dateTime = moment()
          return cartItem
        } else {
          return cartItem
        }
      })
      if (isPriceBreak) {
        let totalSum = 0;
        let tempcatItems = cartItems.map((cartItem, index) => {
          if (cartItem.product.id == newItem.product.id) {
            return cartItem
          }
        }).filter(x => x);
        tempcatItems.map((object, index) => {
          object && object.currentOrder.map((curr, currIndex) => {
            curr && curr.length > 0 && curr.map((sizeObj, index) => {
              totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
            })
          })
        })
        let priceArray = []
        let obj1;
        tempcatItems.map((catItem, index) => {
          let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
          catItem?.currentOrder && catItem?.currentOrder.length > 0 && catItem.currentOrder.map((curr, currIndex) => {
            curr.map((obj, index) => {
              obj1 = productPartArray.find((productPart) => productPart.color == obj.color && productPart.size == obj.size)
              let rPrice = 0
              if (obj1?.size === obj?.size && obj?.color === obj1?.ColorArray?.Color?.colorName) {
                obj1.partPrice.PartPriceArray.PartPrice.map(
                  (partPrice, k) => {
                    if (
                      Number(partPrice.minQuantity) <= Number(totalSum)
                    ) {
                      rPrice = partPrice.salePrice
                    } else if (k === 0) {
                      rPrice = partPrice.salePrice
                    }
                  },
                )
                let withoutDiscount = rPrice
                console.log('withoutDiscount1: ', withoutDiscount);
                let showSell = false
                let showSellPrice = false
                let offerPrice = 0
                let discountType = ''

                let todayDate = new Date().toISOString()

                if (
                  todayDate >= catItem?.product?.startDate &&
                  todayDate <= catItem?.product?.endDate
                ) {
                  if (catItem?.product?.makeProductSale) {
                    showSell = true
                  }
                } else if (
                  catItem?.product?.startDate === '' ||
                  catItem?.product?.startDate === undefined
                ) {
                  if (catItem?.product?.makeProductSale) {
                    showSell = true
                  }
                }

                if (
                  catItem?.product?.showPriceBreak &&
                  todayDate >= catItem?.product?.startDate &&
                  todayDate <= catItem?.product?.endDate
                ) {
                  showSellPrice = true
                }
                if (catItem?.product?.sellingPrice) {
                  offerPrice = catItem?.product?.sellingPrice
                }
                if (
                  catItem?.product?.discountType === '0' ||
                  catItem?.product?.discountType === '1'
                ) {
                  discountType = catItem?.product?.discountType
                }

                if (Number(userGroupDiscount > 0) && Number(withoutDiscount) > 0) {
                  withoutDiscount =
                    Number(withoutDiscount) -
                    (Number(withoutDiscount) * Number(userGroupDiscount)) /
                    100
                  if (withoutDiscount < 0) {
                    withoutDiscount = 0
                  }
                }

                if (discountType === '1' && showSellPrice && Number(withoutDiscount) > 0) {
                  if (offerPrice > 0) {
                    withoutDiscount =
                      Number(withoutDiscount) - Number(offerPrice)
                    if (withoutDiscount < 0) {
                      withoutDiscount = 0
                    }
                  }
                } else if (discountType === '0' && showSellPrice && Number(withoutDiscount) > 0) {
                  if (offerPrice > 0) {
                    withoutDiscount =
                      Number(withoutDiscount) -
                      (Number(withoutDiscount) * Number(offerPrice)) / 100
                    if (withoutDiscount < 0) {
                      withoutDiscount = 0
                    }
                  }
                }
                obj.price = withoutDiscount
              }
            })
            /*if (obj1) {
                priceArray.push(obj1)
            }*/
          })
        })
        /*let minQuantity = []
        priceArray.map((price) => {
            let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
                (val) => Number(val.minQuantity) <= Number(totalSum),
            )
            minQuantity.push(min)
        })
        let allMaxQty = []
        minQuantity.map((qtyMin) => {
            let maxQty = qtyMin && qtyMin.length > 0 && qtyMin.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
            if (maxQty) {
                allMaxQty.push(maxQty)
            }
        })
        let salePrice = allMaxQty.length > 0 && allMaxQty.reduce((prev, current) => (Number(prev?.salePrice) > Number(current?.salePrice)) ? prev : current)
        cartItems.map((cartItem, index) => {
            if (cartItem.product.id == newItem.product.id) {
                cartItem.currentOrder.map((curr, currIndex) => {
                    curr.map((order, index) => {
                        order.price = (salePrice?.salePrice && Number(salePrice?.salePrice) != 0) ? Number(salePrice?.salePrice) : Number(order.price)
                    })
                })
            }
        });*/
      }
      cartItems = cartItems.filter((item) => item.currentOrder.length)
      updateCart(cartItems, publicIp)
      return cartItems
    }
    return {...state, cartItems: remainingItems(cartItems, product), pendingCartItems};
  }
  if (action.type === DELETE_SIZE_FROM_CART) {
    let sizeindexTemp = action.sizeindex
    let rowIndexTemp = action.rowIndex
    let isPriceBreak = action.isPriceBreak
    let userGroupDiscount = action.userGroupDiscount
    console.log('userGroupDiscount: ', userGroupDiscount);
    const remainingItems = (cartItems, product) => {
      cartItems.filter((cartItem) => {
        if (cartItem.product.id == product.product.id) {
          let tempOrder = cartItem.currentOrder
          if (tempOrder[rowIndexTemp]) {
            let tempRow = tempOrder[rowIndexTemp]
            if (tempRow[sizeindexTemp]) {
              tempRow = tempRow.filter((rowSata, key) => key !== sizeindexTemp)
              tempOrder[rowIndexTemp] = tempRow
            }
            tempOrder[rowIndexTemp] = tempRow
          }
          tempOrder = tempOrder.filter((rowArray) => rowArray.length)
          cartItem.currentOrder = tempOrder
          cartItem.dateTime = moment()
          return cartItem
        } else {
          return cartItem
        }
      })
      if (isPriceBreak) {
        let totalSum = 0;
        cartItems.map((object, index) => {
          object && object.currentOrder.map((curr, currIndex) => {
            curr && curr.length > 0 && curr.map((sizeObj, index) => {
              totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
            })
          })
        })
        let obj1;
        cartItems.map((catItem, index) => {
          let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
          catItem?.currentOrder && catItem?.currentOrder.length > 0 && catItem.currentOrder.map((curr, currIndex) => {
            curr.map((obj, index) => {
              obj1 = productPartArray.find((productPart) => productPart.color == obj.color && productPart.size == obj.size)
              let rPrice = 0
              if (obj1?.size === obj?.size && obj?.color === obj1?.ColorArray?.Color?.colorName) {
                obj1.partPrice.PartPriceArray.PartPrice.map(
                  (partPrice, k) => {
                    if (
                      Number(partPrice.minQuantity) <= Number(totalSum)
                    ) {
                      rPrice = partPrice.salePrice
                    } else if (k === 0) {
                      rPrice = partPrice.salePrice
                    }
                  },
                )
                let withoutDiscount = rPrice
                let showSell = false
                let showSellPrice = false
                let offerPrice = 0
                let discountType = ''

                let todayDate = new Date().toISOString()

                if (
                  todayDate >= catItem?.product?.startDate &&
                  todayDate <= catItem?.product?.endDate
                ) {
                  if (catItem?.product?.makeProductSale) {
                    showSell = true
                  }
                } else if (
                  catItem?.product?.startDate === '' ||
                  catItem?.product?.startDate === undefined
                ) {
                  if (catItem?.product?.makeProductSale) {
                    showSell = true
                  }
                }

                if (
                  catItem?.product?.showPriceBreak &&
                  todayDate >= catItem?.product?.startDate &&
                  todayDate <= catItem?.product?.endDate
                ) {
                  showSellPrice = true
                }
                if (catItem?.product?.sellingPrice) {
                  offerPrice = catItem?.product?.sellingPrice
                }
                if (
                  catItem?.product?.discountType === '0' ||
                  catItem?.product?.discountType === '1'
                ) {
                  discountType = catItem?.product?.discountType
                }

                if (Number(userGroupDiscount > 0) && Number(withoutDiscount) > 0) {
                  withoutDiscount =
                    Number(withoutDiscount) -
                    (Number(withoutDiscount) * Number(userGroupDiscount)) /
                    100
                  if(withoutDiscount < 0) {
                    withoutDiscount = 0
                  }
                }

                if (discountType === '1' && showSellPrice && Number(withoutDiscount) > 0) {
                  if (offerPrice > 0) {
                    withoutDiscount =
                      Number(withoutDiscount) - Number(offerPrice)
                    if (withoutDiscount < 0) {
                      withoutDiscount = 0
                    }
                  }
                } else if (discountType === '0' && showSellPrice && Number(withoutDiscount) > 0) {
                  if (offerPrice > 0) {
                    withoutDiscount =
                      Number(withoutDiscount) -
                      (Number(withoutDiscount) * Number(offerPrice)) / 100
                    if (withoutDiscount < 0) {
                      withoutDiscount = 0
                    }
                  }
                }
                obj.price = withoutDiscount
              }
            })
          })
        })
      }
      cartItems = cartItems.filter((item) => item.currentOrder.length)
      updateCart(cartItems, publicIp)
      return cartItems
    }
    return {cartItems: remainingItems(cartItems, product), pendingCartItems};
  }
  if (action.type === CART_DATA) {
    const { cartItems, _id } = action.payload
    return {...state, cartItems: cartItems ?? [], pendingCartItems, id: _id};
  }
  if (action.type === COMPLETE_KIT) {
    let updatedCartItems = [
      ...cartItems,
      ...pendingCartItems.map((item) =>
      item.product.kitId === action.payload
        ? {
          ...item,
          pending: false,
        }
        : item,
      )
    ]
    let updatedPendingCartItems = pendingCartItems.filter((item) => item.product.kitId !== action.payload);
    updateCart(updatedCartItems, publicIp);
    return {...state, cartItems: updatedCartItems, pendingCartItems: updatedPendingCartItems};
  }
  if (action.type === EDIT_KIT_CART_ITEM) {
    let { kitId, productId } = action.payload;
    let updatedPendingCartItems = pendingCartItems.map((item) => {
      if (item.product.kitId === kitId) {
        if (item.product.id === productId) {
          return {
            ...item,
            editing: true,
          };
        } else {
          return {
            ...item,
            editing: false,
          }
        }
      } else {
        return item;
      }
    });
    return {...state, cartItems, pendingCartItems: updatedPendingCartItems};
  }
  return state
};

export default cartReducer;
