import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { OrderItem } from "../models/OrderItem";
import { SerializedError } from "@reduxjs/toolkit";
import { CheckoutQuestion, CheckoutQuestionForm } from "../models/CheckoutQuestion";
import { Question } from "../models/Question";

export function getItemColorText(item: OrderItem): string {
    const set = new Set(item.rows.map(r => r.color));
    if (set.size === 1) {
        return item.rows[0].color;
    } else {
        return "Multiple";
    }
}

export function getItemSizesText(item: OrderItem): string {
    const set = new Set(item.rows.map(r => r.size));
    if (set.size === 1) {
        return item.rows[0].size;
    } else {
        return "Multiple";
    }
}

export function getError(error: FetchBaseQueryError | SerializedError | undefined) {
    if (error) {
        if ("data" in error) {
            const message = (error as any).data?.message;
            if (typeof message === 'string') {
                return message;
            } else if (Array.isArray(message)) {
                return message.join(", ");
            }
        }
    }
    return "An error occurred";
}

export function getFormQuestions(questions: Question[], checkoutQuestions: CheckoutQuestion[]): CheckoutQuestionForm[] {
    return questions.map(q => {
        const existingQuestion = checkoutQuestions.find(cq => cq.questionId === q._id);
        return {
            ...q,
            questionId: q._id,
            choiceValue: existingQuestion?.choiceValue ?? "",
            value: existingQuestion?.value ?? "",
            choices: q.choices
        }
    })
}