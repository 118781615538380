import React from "react";
import { Grid2, Typography } from "@mui/material";
import { useAppSelector } from "../../../redux/hooks";
import { selectContactInfo, selectParams, selectQuestions } from "../../../redux/selectors/checkoutSelectors";
import PassiveRow from "../PassiveRow";

export default function CustomerDetailsPassiveContent() {
    const params = useAppSelector(selectParams);
    const questions = useAppSelector((state) => selectQuestions(state, params));
    const contactInfo = useAppSelector((state) => selectContactInfo(state, params));

    return (
        <Grid2 container columnSpacing={2}>
            <PassiveRow field={"First Name"} value={contactInfo?.firstName} />
            <PassiveRow field={"Last Name"} value={contactInfo?.lastName} />
            <PassiveRow field={"Email"} value={contactInfo?.email} />
            {questions?.map(q => 
                <PassiveRow
                    field={q.question}
                    value={q.choiceValue + ((q.choiceValue && q.value) ? ` - ${q.value}` : q.value)}
                />
            )}
        </Grid2>
    );
}