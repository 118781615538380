import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../common/ActionUrl';
import { CheckoutSession } from '../../models/CheckoutSession';
import { CheckoutQuestion } from '../../models/CheckoutQuestion';
import { Question, QuestionResponse } from '../../models/Question';

export interface GetOrCreateSessionParams {
    cartId: string;
    userId: string;
}

export interface UpdateCustomerDetails {
    sessionId: string;
    firstName: string;
    lastName: string;
    questions: CheckoutQuestion[];
}

export const aetherApi = createApi({
    reducerPath: 'aetherApi',
    tagTypes: ['CheckoutSession', 'Questions'],
    baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
    endpoints: (builder) => ({
        getOrCreateCheckoutSession: builder.query<CheckoutSession, GetOrCreateSessionParams>({
            query: ({ userId, cartId }) => {
                return {
                    url: `api/v2/checkout/getOrCreateSession`,
                    
                    method: 'POST',
                    body: {
                        cartId: cartId,
                        userId: userId,
                    }
                };
            },
            providesTags: ['CheckoutSession'],
        }),
        updateCustomerDetails: builder.mutation<CheckoutSession, UpdateCustomerDetails>({
            query: (body) => ({
                url: `api/v2/checkout/updateCustomerDetails`,
                method: 'POST',
                body
            }),
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {                    
                    const { data } = await queryFulfilled;
                    if (data) {
                        const { cartId, userId } = data;
                        dispatch(aetherApi.util.updateQueryData('getOrCreateCheckoutSession', { cartId, userId }, (draft) => {
                            Object.assign(draft, data);
                        }));
                    }
                } catch {}
            }
        }),
        getCheckoutQuestions: builder.query<Question[], void>({
            query: () => `api/v1/question`,
            providesTags: ['Questions'],
            transformResponse: (response: QuestionResponse) => response.results.map((question: Question) => question)
        }),
    }),
});

export const { useGetOrCreateCheckoutSessionQuery, useUpdateCustomerDetailsMutation, useGetCheckoutQuestionsQuery } = aetherApi;