import React, { useContext, useEffect } from "react";
import { Button, Card, Container, Grid2, Skeleton, Stack, ThemeProvider, Typography } from "@mui/material";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ItemsCard from "./ItemsCard";
import { AuthContext } from "../../common/Auth";
import { useGetOrCreateCheckoutSessionQuery } from "../../redux/api/aetherApi";
import { newTheme } from "../../components/theme/theme";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import CustomerDetailsCard from "./customerDetails/CustomerDetailsCard";
import { updateSessionParams } from "../../redux/reducers/checkoutReducer";
import { selectCartId } from "../../redux/selectors/checkoutSelectors";

export default function NewCheckout() {
  const dispatch = useAppDispatch();
  const { user, settingData } = useContext(AuthContext);
  const { themedefaultColor, themedefaultTextColor } = useThemeSettings({ settingData });
  const cartId = useAppSelector(selectCartId);
  const { data: cartSession, isSuccess, isLoading } = useGetOrCreateCheckoutSessionQuery({ cartId, userId: user.userId ?? localStorage.getItem("-") }, {skip: !cartId});

  // Can be replaced with a selector once user ID is in Redux
  useEffect(() => {
    if (cartId) {
      dispatch(updateSessionParams({ userId: user.userId ?? localStorage.getItem("-"), cartId }));
    }
  }, [user, cartId]);

  return (
    <ThemeProvider theme={newTheme({ themedefaultColor, themedefaultTextColor })}>
      <Container maxWidth={"lg"} sx={{mt: 2, mb: 2, height: "100%"}}>
        <Stack direction={"column"} alignItems={"stretch"} height={"100%"}>
          <Typography variant={"h4"} gutterBottom>Checkout</Typography>
          <Grid2 container flexGrow={1} spacing={2}>
            <Grid2 size={8}>
              <Stack height={"100%"} spacing={2}>
                {isLoading && <Skeleton variant="rounded" height={"34%"} />}
                {isLoading && <Skeleton variant="rounded" height={"33%"} />}
                {isLoading && <Skeleton variant="rounded" height={"33%"} />}
                {isSuccess && <CustomerDetailsCard />}
              </Stack>
            </Grid2>
            <Grid2 size={4}>
              {isLoading && <Skeleton variant="rounded" height={"100%"} />}
              {isSuccess && <ItemsCard />}
            </Grid2>
          </Grid2>
        </Stack>
      </Container>
    </ThemeProvider>
  );
};